<template>
  <section class="catalog">
    <div class="container catalog-container">
      <h1 class="catalog-title" v-if="titleIsH1">Каталог</h1>
      <h2 class="catalog-title" v-else>Каталог</h2>
      <!-- Выводим каталог, если он есть -->
      <div v-if="$store.state.database.catalog">
        <template v-if="$store.state.database.catalog.groups">
          <div class="catalog-group" v-for="(group, index) of $store.state.database.catalog.groups" :key="index">
            <template v-if="group.products.length > 0">
              <h3 class="catalog-group-title" :id="group.id">{{ group.name }}</h3>
              <div class="catalog-layout">
                <AppCatalogCard :products="group.products" />
              </div>
            </template>
          </div>
        </template>
      </div>
      <!-- Выводим заглушку, если каталога нет -->
      <div v-else>Пока здесь ничего нет. Вернитесь на сайт позже, каталог появится.</div>
    </div>
  </section>
</template>

<script>
import AppCatalogCard from "./AppCatalogCard.vue";

// import DataBase from "../../public/database.json";
// const serverData = DataBase;

export default {
  name: "AppCatalog",
  props: {
    titleIsH1: { type: Boolean, default: false },
  },
  components: {
    AppCatalogCard,
  },
};
</script>

<style lang="scss">
// Каталог
.catalog {
  &-group {
    margin: 0 0 40px 0;
  }

  &-title {
    margin-bottom: 40px;
  }

  &-layout {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 40px;
    row-gap: 40px;

    @media (max-width: $brLaptop) {
      column-gap: 20px;
    }

    @media (max-width: $brTablet) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: $brTabletMd) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: $brTabletSm) {
      row-gap: 32px;
    }

    @media (max-width: $brMobile) {
      // Преобразуем сетку в ряд с возможностью скролла
      display: flex;
      column-gap: 16px;

      overflow-x: scroll;

      margin: 0 -16px;
      padding: 0 32px 32px 16px;
    }
  }
}
</style>
