<template>
  <section>
    <div class="container">
      <h1>Избранное</h1>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Porro, nisi dolores velit ex sunt praesentium nostrum minima modi recusandae eveniet magnam odit exercitationem. Quia blanditiis, harum pariatur ullam id, laborum ducimus,
        quae voluptatibus impedit consequuntur fuga. Error in dolor, repellat magnam voluptatum ipsa accusamus deserunt minus soluta libero corporis autem!
      </p>
    </div>
  </section>
  <section>
    <div class="container">
      <div v-if="$store.state.allProducts" class="catalog-layout">
        <AppCatalogCard :products="$store.state.allProducts" />
      </div>
      <div v-else>Избранное пока пустое</div>
    </div>
  </section>
</template>

<script>
import AppCatalogCard from "./AppCatalogCard.vue";

export default {
  name: "AppFav",
  components: {
    AppCatalogCard,
  },
};
</script>

<style lang="scss">
@import "../components/app/textpage.scss";
</style>
