<template>
  <section>
    <div class="container">
      <template v-if="$store.state.allSuggestedProducts">
        <h2 class="catalog-group-title">Лучшие продукты</h2>
        <div class="catalog-layout" style="margin-bottom: 40px">
          <AppCatalogCard :products="$store.state.allSuggestedProducts" />
        </div>
      </template>

      <router-link to="/catalog" class="catalog-preview-link" title="Перейти в каталог"> Все продукты </router-link>
    </div>
  </section>
</template>

<script>
import AppCatalogCard from "./AppCatalogCard.vue";

export default {
  name: "AppCatalogSuggested",
  components: {
    AppCatalogCard,
  },
};
</script>

<style lang="scss" scoped></style>
