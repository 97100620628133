<template>
  <form class="form form-order" role="form" title="">
    <button class="form-button-close" type="button" role="button" v-if="hasClosingButton" v-on:click="closePopup" title="Закрыть окно"></button>
    <h2 class="form-title">Оставить заявку на товар</h2>
    <div class="form-order-layout">
      <div class="form-group">
        <div class="form-label">Ваше имя:</div>
        <input type="text" name="user-name" placeholder="Джеймс Хэтфилд" class="form-input" required />
      </div>
      <div class="form-group">
        <div class="form-label">Ваш телефон:</div>
        <input type="tel" name="user-tel" placeholder="В любом формате" class="form-input input-mask-tel" required />
      </div>

      <div class="form-group">
        <div class="form-label">Что вы хотите заказать?</div>
        <AppPseudoSelect :hasNote="true" :placeholder="'Выберите группу товаров'" :noteText="'Отметьте интересующие Вас товары. Мы свяжемся и уточним наличие и актуальные цены.'" />
      </div>
      <div class="form-group">
        <div class="form-label">Предпочитаемый способ связи:</div>
        <AppPseudoSelect />
      </div>
    </div>

    <div class="form-group">
      <div class="form-submit-note">Оставляя заявку на обратную связь, вы даете согласие на хранение и обработку персональных данных.</div>
      <button type="submit" role="button" class="form-submit-button" :class="formValid ? 'form-submit-button-valid' : 'form-submit-button-disabled'" :title="formValid ? 'Отправить форму' : 'Заполните все обязательные поля'">
        Свяжитесь со мной
      </button>
    </div>
  </form>
</template>

<script>
import AppPseudoSelect from "./AppPseudoSelect.vue";
export default {
  name: "AppFormOrder",
  components: { AppPseudoSelect },
  props: { hasClosingButton: { type: Boolean, default: false } },
  data: () => ({
    formValid: false,
  }),
  methods: {
    closePopup(event) {
      let popup = event.target.closest(".overlay");
      if (popup) {
        popup.classList.remove("activated");
        document.querySelector("html").classList.remove("locked");
        document.querySelector("body").classList.remove("locked");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
// .overlay {
//   // overflow: scroll;

//   &-container {
//     // padding: 200px 0 !important;
//     overflow: scroll;
//   }

//   .form {
//     width: 100%;
//     max-width: 100%;
//   }
// }

.form-order {
  // margin: 100px 0;
  width: 800px;

  &-layout {
    margin: 20px 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: space-between;
    row-gap: 20px;
    column-gap: 40px;

    @media (max-width: $brTabletSm) {
      grid-template-columns: 1fr;
    }
  }
}
</style>
