<template>
  <section class="contacts">
    <div class="container contacts-container">
      <h1>Контакты</h1>
      <div class="contacts-layout">
        <div class="contacts-card">
          <div class="contacts-group">
            <h2 class="contacts-card-title">Телефон:</h2>
            <a href="tel:+7 922 628 7911" class="contacts-card-tel" title="Перейти к набору номера"> +7 922 628 7911 </a>
            <p>Без выходных, с 10:00 до 17:00.</p>
          </div>
          <div class="contacts-group">
            <h2 class="contacts-card-title">Мессенджеры:</h2>
            <div class="messengers contacts-messengers">
              <a href="tg://msg?to=+79226287911" target="_blank" rel="noopener noreferrer" class="messengers-link" title="Напишите нам в Телеграм">
                <img src="./telegram.svg" alt="telegram image" />
              </a>
              <a href="https://wa.me/79228296911" target="_blank" rel="noopener noreferrer" class="messengers-link" title="Напишите нам в What's App">
                <img src="./whatsapp.svg" alt="whatsapp image" />
              </a>
              <a href="viber://chat/number=+7 922 628 7911" target="_blank" rel="noopener noreferrer" class="messengers-link" title="Напишите нам в Viber">
                <img src="./viber.svg" alt="viber image" />
              </a>
            </div>
            <p>В любое время.</p>
          </div>
        </div>

        <!-- <AppFormFeedback /> -->
      </div>
    </div>
  </section>

  <section id="map">
    <div class="container">
      <h2>Офис</h2>
      <div style="font-weight: 400; margin-bottom: 40px; display: flex; flex-direction: column; row-gap: 16px">
        <p>Оренбург, переулок Слесарный 1/1</p>
        <p>Пн-Сб, с 10:00 до 17:00</p>
      </div>
      <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3Af597aa9ff854068bceced941e76a8b755eec8681d32fae07c30fe3069f9c88b3&amp;source=constructor" width="100%" height="480" frameborder="0"></iframe>
    </div>
  </section>
</template>

<script>
// import AppPseudoSelect from "../components/AppPseudoSelect.vue";
// import AppFormFeedback from "../components/AppFormFeedback.vue";
export default {
  name: "PageContacts",
  components: {
    // AppPseudoSelect,
    // AppFormFeedback,
  },
};
</script>

<style lang="scss" scoped>
h1 {
  margin: 0 0 40px 0;
}
.contacts {
  &-layout {
    display: flex;
    justify-content: space-between;
    column-gap: 200px;

    @media (max-width: $brTablet) {
      column-gap: 40px;
    }

    @media (max-width: $brTabletSm) {
      flex-wrap: wrap;
    }
  }

  &-card {
    flex-grow: 1;

    &-tel {
      width: fit-content;
      &:hover {
        color: $colorMain;
      }
    }

    &-title,
    &-tel {
      font-size: 24px;
      font-weight: 400;
      margin: 0;
    }
  }
  &-group {
    display: flex;
    flex-direction: column;
    // column-gap: 20px;
    row-gap: 10px;

    margin: 0 0 40px 0;
  }
  // p {
  //   font-size: 14px;
  // }

  &-messengers {
    &-link,
    a {
      width: 40px;
      height: 40px;
      background: none;

      border-radius: 50%;
      overflow: hidden;

      @extend %flex-center-center;
    }
  }

  &-form {
    margin: 0;
    &-title {
      &-note {
        margin-top: -10px;
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
}

iframe {
  border-radius: 16px;
  box-shadow: 0px 0px 20px 0px rgba(black, 0.1);
}
</style>
