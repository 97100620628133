<template>
  <AppHeader />
  <AppCatalogPreview />
  <AppReviews />
  <!-- <AppGallery /> -->
  <AppPartners />
</template>

<script>
import AppHeader from "../components/AppHeader.vue";
import AppCatalogPreview from "../components/AppCatalogPreview.vue";
import AppPartners from "../components/AppPartners.vue";
import AppReviews from "../components/AppReviews.vue";
// import AppGallery from "../components/AppGallery.vue";

export default {
  name: "PageIndex",
  components: {
    AppHeader,
    AppCatalogPreview,
    AppPartners,
    AppReviews,
    // AppGallery,
  },
};
</script>
