<template>
  <section class="partners">
    <div class="container partners-container">
      <h2 class="partners-title">Партнеры</h2>
      <div class="partners-banner" role="banner">
        <div class="partners-banner-text">
          <div>
            <h3 class="partners-banner-title">Благоустройство дома и участка</h3>
            <div class="partners-banner-description">Производство и монтаж заборов, ворот, навесов, мангальных зон и многое другое.</div>
          </div>
          <a href="http://orenzabor.ru" target="_blank" rel="noopener noreferrer" title="Перейти на orenzabor.ru" class="partners-banner-logo"> ОРЕНЗАБОР </a>
        </div>

        <a href="http://orenzabor.ru" target="_blank" rel="noopener noreferrer" title="Перейти на orenzabor.ru" class="partners-banner-link"> Подробнее </a>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "AppPartners",
};
</script>

<style lang="scss" scoped>
@font-face {
  font-family: "Retro";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("./font-retrowave-7.ttf") format("truetype");
}

.partners {
  &-banner {
    padding: 40px;
    background: #faf8fc;
    border-radius: 16px;

    @media (max-width: $brTabletMd) {
      padding: 32px 20px;
    }

    @media (max-width: $brMobile) {
      padding: 32px 16px;
    }

    &-text {
      display: grid;
      grid-template-columns: 1fr 1fr;
      justify-content: space-between;

      column-gap: 20px;
      row-gap: 20px;

      margin-bottom: 20px;

      @media (max-width: $brTabletMd) {
        grid-template-columns: 1fr;
        margin-bottom: 40px;
      }
    }

    &-title {
      margin: 0 0 20px 0;
    }

    &-link {
      width: 240px;
      height: 48px;

      @extend %flex-center-center;

      border-radius: 8px;
      background: #51bc71;

      color: white;
      font-weight: 700;
    }

    &-logo {
      font-family: "Retro";

      color: #51bc71;
      font-size: 80px;
      font-weight: 700;

      background: white;
      border-radius: 16px;

      padding: 10px 20px;

      @extend %flex-center-center;

      @media (max-width: $brTablet) {
        font-size: 64px;
      }

      @media (max-width: $brTabletSm) {
        font-size: 56px;
      }

      @media (max-width: $brMobile) {
        font-size: 40px;
      }

      @media (max-width: $brMobileSm) {
        font-size: 36px;
      }
    }
  }
}
</style>
