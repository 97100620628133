<template>
  <AppFav />
</template>

<script>
import AppFav from "../components/AppFav.vue";
export default {
  name: "PageFav",
  components: {
    AppFav,
  },
};
</script>
