<template>
  <div class="catalog-card" v-for="(card, index) of products" :key="index">
    <template v-if="card.new">
      <div class="catalog-card-new">Новинка</div>
    </template>
    <template v-else-if="card.suggest">
      <div class="catalog-card-suggest">Рекомендуем</div>
    </template>

    <div class="catalog-card-top">
      <router-link :to="{ name: 'Страница товара', params: { id: card.id, name: card.name } }" class="catalog-card-title" :class="card.new || card.suggest ? 'catalog-card-title-short' : ''" title="Перейти на страницу товара">
        {{ card.name }}
      </router-link>

      <!-- <button class="catalog-card-fav-button" :data-id="card.id" title="Добавить в избранное" role="button" type="button" v-on:click="toggleToFavorite($event), $store.commit('countFavorites')"></button> -->
    </div>

    <swiper
      :class="'catalog-card-slider'"
      :modules="modules"
      :slides-per-view="slidesPerView"
      :space-between="spaceBetween"
      :loop="loop"
      :speed="speed"
      :scrollbar="scrollbar"
      grab-cursor
      :slide-to-clicked-slide="slideToClickedSlide"
      :preload-images="preloadImages"
      :lazy="lazy"
      watch-slides-progress
      watch-slides-visibility
      mousewheel
    >
      <template v-slot:wrapper-start>
        <template v-for="(img, index) of card.imgs" :key="index">
          <swiper-slide>
            <img src="./thumb.jpg" :alt="img.alt" :data-src="img.src" class="swiper-lazy catalog-card-slider-img" />
            <div class="swiper-lazy-preloader"></div>
          </swiper-slide>
        </template>
      </template>
      <template v-slot:container-end>
        <div class="swiper-scrollbar catalog-card-slider-scrollbar"></div>
      </template>
    </swiper>

    <div class="catalog-card-description">
      {{ card.description }}
    </div>
    <div class="catalog-card-price">{{ card.price }} ₽</div>

    <router-link :to="{ name: 'Страница товара', params: { id: card.id } }" class="catalog-card-link" title="Перейти на страницу товара"> Подробнее </router-link>
    <AppButtonPopup :classes="'catalog-card-order-button order-button'" :targetPopup="'#popup-feedback'" :text="'Заказать'" />
  </div>
</template>

<script>
// Импортируем модули слайдера
import { A11y, Lazy, Scrollbar, Mousewheel } from "swiper";
// Импортируем слайдер
import { Swiper, SwiperSlide } from "swiper/vue";
// Импортируем стили слайдера
import "swiper/scss";
import "swiper/scss/lazy";
import "swiper/scss/scrollbar";

import AppButtonPopup from "./AppButtonPopup.vue";

export default {
  name: "AppCatalogCard",
  components: {
    Swiper,
    SwiperSlide,
    AppButtonPopup,
  },
  props: {
    // Массив товаров
    products: {
      type: Array,
    },
  },
  setup() {
    return {
      modules: [A11y, Lazy, Scrollbar, Mousewheel],
      loop: false,
      speed: 334,
      slidesPerView: 1.0,
      spaceBetween: 10,
      grabCursor: true,
      slideToClickedSlide: true,
      scrollbar: {
        draggable: true,
      },
      // Ленивая загрузка
      preloadImages: false,
      lazy: {
        loadOnTransitionStart: false,
        loadPrevNext: false,
      },
      watchSlidesProgress: true,
      watchSlidesVisibility: true,
    };
  },
  methods: {
    toggleToFavorite(event) {
      let button = event.target.closest(".catalog-card-fav-button");
      let id = button.getAttribute("data-id");

      function setFav(products) {
        localStorage.setItem("favorite-products", products);
      }

      // Получаем запись из локального хранилища
      let storage = localStorage.getItem("favorite-products");
      // Если запись есть
      if (storage) {
        let storageArray = storage.split(",");

        // Проверяем есть ли продукт в массиве
        if (storageArray.includes(id)) {
          // Создаем новый массив
          let add = storageArray.filter(function (element) {
            // Выводим все элементы, кроме текущего продукта
            return element !== id;
          });
          // Таким образом на выходе получаем массив, в котором удален добавленный продукт

          // Записываем в локальное хранилище
          setFav(add);
        }
        // Если продукта нет в массиве
        else {
          let add = storage + "," + id;
          // Записываем в локальное хранилище
          setFav(add);
        }
      }
      // Если записи нет
      // Если в избранном нет ни одного продукта
      else {
        setFav(id);
      }

      // Переключаем стили кнопки
      button.classList.toggle("activated");
      // Подсчитываем количество продуктов в избранном
      console.log(localStorage.getItem("favorite-products").split(",").length);
    },
  },
};
</script>

<style scoped lang="scss">
.catalog {
  &-card {
    position: relative;
    // display: block;

    display: flex;
    flex-direction: column;

    min-width: 0;

    background: white;
    box-shadow: 0px 5px 20px rgba(black, 0.1);
    border-radius: 8px;
    padding: 20px 20px 30px 20px;

    @media (max-width: $brTablet) {
      padding: 16px 16px 24px 16px;
    }

    @media (max-width: $brMobile) {
      flex-shrink: 0;
      max-width: 240px;
      padding: 16px;
    }

    &-new,
    &-suggest {
      background: #ec3e16;
      clip-path: polygon(100% 0, 100% 100%, 0% 100%, 12.5% 50%, 0% 0%);
      // border-radius: 8px;

      color: white;
      font-size: 13px;
      font-weight: 600;

      position: absolute;
      top: 10px;
      right: 0;
      // width: 80px;
      height: 20px;
      padding: 0 10px 0 20px;

      // margin: 0 0 10px auto;

      @extend %flex-center-center;
    }

    &-suggest {
      background: #80cd5a;
    }

    &-top {
      display: flex;
      justify-content: space-between;
      // align-items: center;
      align-items: flex-start;
      margin: 0 0 15px 0;

      @media (max-width: $brTabletSm) {
        margin-bottom: 10px;
      }
    }

    &-title {
      height: 45px;
      font-weight: 600;
      font-size: 18px;

      transition: $transitionDefault;

      @media (max-width: $brTabletSm) {
        font-size: 16px;
      }

      &:hover {
        color: $colorMain;
      }

      &-short {
        @media (max-width: $brTabletSm) {
          max-width: 150px;
        }
      }
    }

    &-fav-button {
      width: 26px;
      height: 23px;

      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;

      background-image: url("data:image/svg+xml,%3Csvg width='26' height='23' viewBox='0 0 26 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M25.1597 8.09896L17.3654 7.04962L13.8811 0.506195C13.7859 0.32704 13.6294 0.182009 13.436 0.0938536C12.9509 -0.127957 12.3615 0.0568852 12.119 0.506195L8.63477 7.04962L0.840471 8.09896C0.625583 8.12739 0.429114 8.22124 0.278692 8.36342C0.0968411 8.53657 -0.00336732 8.76951 8.64032e-05 9.01106C0.00354013 9.25261 0.110373 9.48301 0.297111 9.65163L5.93639 14.7448L4.60408 21.9365C4.57284 22.1038 4.59282 22.2759 4.66177 22.4332C4.73072 22.5905 4.84586 22.7268 4.99416 22.8266C5.14245 22.9263 5.31796 22.9856 5.50078 22.9977C5.68359 23.0098 5.86641 22.9742 6.02848 22.8949L13.0001 19.4995L19.9716 22.8949C20.162 22.9887 20.383 23.02 20.5948 22.9859C21.129 22.9006 21.4881 22.4314 21.396 21.9365L20.0637 14.7448L25.703 9.65163C25.8565 9.51229 25.9578 9.33029 25.9885 9.13123C26.0714 8.63358 25.6969 8.17289 25.1597 8.09896Z' fill='%23E5E5E5'/%3E%3C/svg%3E%0A");

      &.activated {
        $colorYellow: #f8eb5b;
        fill: $colorYellow;
        color: $colorYellow;

        background-image: url("data:image/svg+xml,%3Csvg width='26' height='23' viewBox='0 0 26 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M25.1597 8.09896L17.3654 7.04962L13.8811 0.506195C13.7859 0.32704 13.6294 0.182009 13.436 0.0938536C12.9509 -0.127957 12.3615 0.0568852 12.119 0.506195L8.63477 7.04962L0.840471 8.09896C0.625583 8.12739 0.429114 8.22124 0.278692 8.36342C0.0968411 8.53657 -0.00336732 8.76951 8.64032e-05 9.01106C0.00354013 9.25261 0.110373 9.48301 0.297111 9.65163L5.93639 14.7448L4.60408 21.9365C4.57284 22.1038 4.59282 22.2759 4.66177 22.4332C4.73072 22.5905 4.84586 22.7268 4.99416 22.8266C5.14245 22.9263 5.31796 22.9856 5.50078 22.9977C5.68359 23.0098 5.86641 22.9742 6.02848 22.8949L13.0001 19.4995L19.9716 22.8949C20.162 22.9887 20.383 23.02 20.5948 22.9859C21.129 22.9006 21.4881 22.4314 21.396 21.9365L20.0637 14.7448L25.703 9.65163C25.8565 9.51229 25.9578 9.33029 25.9885 9.13123C26.0714 8.63358 25.6969 8.17289 25.1597 8.09896Z' fill='%23f8eb5b'/%3E%3C/svg%3E%0A");
      }
    }

    &-slider {
      width: 100%;
      // height: 160px;

      border-radius: 8px;
      margin: 0 0 10px 0;

      overflow: hidden;
      position: relative;

      &-img {
        width: 100%;
        height: 160px;

        object-fit: cover;

        @media (max-width: $brTabletSm) {
          height: 120px;
        }
      }
    }
    &-description {
      font-size: 16px;
      margin: 0 0 10px 0;

      @media (max-width: $brTabletSm) {
        font-size: 14px;
      }
    }

    &-price {
      width: 100%;
      text-align: right;
      font-weight: 500;
      font-size: 18px;

      margin: auto 0 20px 0;

      @media (max-width: $brTabletSm) {
        font-size: 16px;
      }
    }

    &-link {
      background: $colorMinor;
      border-radius: 8px;
      width: 100%;
      height: 40px;

      font-size: 16px;
      font-weight: 600;

      @extend %flex-center-center;
      margin: 0 0 10px 0;

      @media (max-width: $brTabletSm) {
        font-size: 14px;
        // height: 36px;
      }
    }

    &-order-button {
      background: $colorMain;
      border-radius: 8px;
      width: 100%;
      height: 40px;

      font-weight: 700;
      font-size: 16px;

      color: white;

      &:hover {
        background: $colorMainHover;
      }

      @media (max-width: $brTabletSm) {
        font-size: 14px;
        // height: 36px;
      }
    }
  }
}
</style>
