<template>
  <div class="container">
    <h1>Опт и скидки</h1>

    <div class="note">Все скидки и предложения можно рассчитать в калькуляторе на детальной странице товара. Например, <router-link to="/catalog/product-greece">на странице тротуарной плитки малая Греция</router-link>.</div>

    <details class="details" open>
      <summary title="Раскрыть/закрыть сниппет">
        Скидка за наличные
        <div class="date">
          <div class="date-text">Действует до:</div>
          <div class="date-number">01</div>
          <div class="date-number">09</div>
          <div class="date-number">22</div>
        </div>
      </summary>
      <div class="details-content">
        <div>5% при расчете за наличные. Распостраняется на все товары.</div>

        <router-link to="/catalog" title="Перейти в каталог"> Перейти в каталог </router-link>
      </div>
    </details>
    <details class="details" open>
      <summary title="Раскрыть/закрыть сниппет">
        Оптовикам
        <div class="date">
          <div class="date-text">Действует до:</div>
          <div class="date-number">01</div>
          <div class="date-number">06</div>
          <div class="date-number">22</div>
        </div>
      </summary>
      <div class="details-content">
        <div>Скидка 3% при покупке от 150 ед. и 5% при покупке от 300 ед. Распостраняется на все товары.</div>

        <router-link to="/catalog" title="Перейти в каталог"> Перейти в каталог </router-link>
      </div>
    </details>
  </div>
</template>

<script>
export default {
  name: "PageWholesale",
};
</script>

<style lang="scss" scoped>
@import "../components/app/textpage.scss";

.note {
  margin: 0 0 20px 0;

  a {
    display: inline;
    font-weight: 600;
    color: $colorMain;
    &:hover {
      text-decoration: underline;
    }
  }
}

details {
  &[open] {
    summary {
      border-color: $colorGrey;
    }
  }
}

summary {
  padding-block: 12px;
  border-bottom: 1px solid transparent;

  display: flex;
  justify-content: space-between;
  align-items: center;

  transition: 0.334s;

  font-size: 18px;

  @media (max-width: $brTabletMd) {
    font-size: 16px;
  }

  @media (max-width: $brMobile) {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 8px;
  }
  // &:hover {
  //   color: $colorMain;
  // }
}

.details {
  padding: 24px 16px 12px 16px;
  max-width: 50%;

  @media (max-width: $brTabletMd) {
    // padding: 24px 8px;
    max-width: 100%;
  }

  &-content {
    padding: 12px 0 0 0;
  }

  a {
    display: inline-block;

    margin: 8px 24px 0 0;
    font-weight: 600;
    color: $colorMain;

    &:hover {
      text-decoration: underline;
    }
  }
}

.date {
  display: flex;
  align-items: center;
  column-gap: 8px;

  font-size: 16px;
  font-weight: 400;

  &-text {
  }

  &-number {
    border-radius: 4px;
    // background: $colorMinor;
    border: 1px solid $colorMinor;
    // box-shadow: 2px 2px 4px 0px rgba(black, 0.1);
    padding: 2px 4px;
  }
}
</style>
