<template>
  <section class="reviews">
    <div class="container reviews-container">
      <swiper
        :modules="modules"
        :slides-per-view="slidesPerView"
        :slides-per-group="slidesPerGroup"
        :space-between="spaceBetween"
        :class="'reviews-slider'"
        :navigation="navigation"
        :pagination="pagination"
        :speed="speed"
        :breakpoints="breakpoints"
      >
        <template v-slot:container-start>
          <div class="reviews-slider-top">
            <h2 class="reviews-slider-title">Отзывы</h2>
            <div class="reviews-slider-controls">
              <button type="button" role="button" class="reviews-slider-button reviews-slider-button-prev" title="Предыдущий слайд"></button>
              <button type="button" role="button" class="reviews-slider-button reviews-slider-button-next" title="Следующий слайд"></button>
            </div>
          </div>
        </template>

        <template v-slot:wrapper-start>
          <template v-for="(slide, index) of $store.state.database.reviews" :key="index">
            <swiper-slide>
              <div class="reviews-slider-slide">
                <div class="reviews-slider-slide-text">
                  {{ slide.text }}
                </div>
                <div class="reviews-slider-slide-label">
                  {{ slide.label }}
                </div>
              </div>
            </swiper-slide>
          </template>
        </template>

        <template v-slot:container-end>
          <div class="reviews-slider-pagination swiper-pagination"></div>
        </template>
      </swiper>
    </div>
  </section>
</template>

<script>
// Импортируем модули слайдера
import { Navigation, Pagination, A11y, Mousewheel } from "swiper";
// Импортируем слайдер
import { Swiper, SwiperSlide } from "swiper/vue";
// Импортируем стили слайдера
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";

export default {
  name: "AppReviews",
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      modules: [Navigation, Pagination, A11y, Mousewheel],
      speed: 334,
      slidesPerView: 2.0,
      slidesPerGroup: 1,
      spaceBetween: 20,
      navigation: {
        prevEl: ".reviews-slider-button-prev",
        nextEl: ".reviews-slider-button-next",
      },
      pagination: {
        el: ".reviews-slider-pagination",
        clickable: true,
      },
      breakpoints: {
        0: {
          slidesPerView: 1.0,
        },
        768: {
          slidesPerView: 1.25,
        },
        1200: {
          slidesPerView: 2.0,
        },
      },
    };
  },
  data: () => ({
    // slides: serverData.reviews,
  }),
};
</script>

<style scoped lang="scss">
.reviews {
  // &-container {
  //   overflow: hidden;
  // }
  &-slider {
    position: relative;
    padding: 40px 0;
    overflow: unset;

    &-top {
      display: flex;
      justify-content: space-between;
      align-items: center;

      margin: 0 0 40px 0;
    }

    &-title {
      margin: 0;
    }

    // Элементы управления слайдером
    &-controls {
      display: flex;
      align-items: center;
      column-gap: 10px;
    }

    &-button {
      $size: 36px;
      width: $size * 2;
      height: $size;

      border-radius: 8px;
      border: 1px solid $colorMinor;
      // background: white;
      // box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.05);

      position: relative;

      opacity: 1;
      visibility: visible;
      transform: none;

      &.swiper-button-disabled {
        opacity: 0.334;
        //  visibility: hidden;
        // transform: scale(0);
        pointer-events: none;
      }

      // &-prev {
      //   &.swiper-button-disabled {
      //     transform: translateX(-20px);
      //   }
      // }
      // &-next {
      //   &.swiper-button-disabled {
      //     transform: translateX(20px);
      //   }
      // }

      @media (any-hover: hover) {
        &:hover {
          box-shadow: 0px 5px 20px 0px rgba(black, 0.1);
        }
      }

      // @media (max-width: $brTablet) {
      //   &:active {
      //     transform: scale(0.9);
      //   }
      // }

      &::before {
        content: "";
        position: absolute;
        z-index: 10;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 18px;
        height: 10px;

        background-position: center center;
        background-repeat: no-repeat;
        background-size: contain;
      }

      &-prev {
        &::before {
          background-image: url("data:image/svg+xml,%3Csvg width='17' height='9' viewBox='0 0 17 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.3609 8.59606C4.42647 8.66535 4.53682 8.66535 4.6024 8.59606C4.66301 8.53201 4.66301 8.43179 4.6024 8.36774L0.942274 4.5L4.6024 0.632258C4.66301 0.568209 4.66301 0.467992 4.6024 0.403942C4.53682 0.334648 4.42647 0.334648 4.3609 0.403942L0.484713 4.5L4.3609 8.59606Z' fill='black'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1.42699 4.5L4.85823 0.874121C5.04726 0.674373 5.04726 0.361828 4.85823 0.162079C4.65373 -0.0540261 4.30957 -0.0540267 4.10506 0.162079L0 4.5L4.10506 8.83792C4.30957 9.05403 4.65373 9.05403 4.85823 8.83792C5.04726 8.63817 5.04726 8.32563 4.85823 8.12588L1.42699 4.5Z' fill='black'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M17 4.5C17 4.22386 16.7761 4 16.5 4H1.5C1.22386 4 1 4.22386 1 4.5C1 4.77614 1.22386 5 1.5 5H16.5C16.7761 5 17 4.77614 17 4.5Z' fill='black'/%3E%3C/svg%3E%0A");
        }
      }
      &-next {
        &::before {
          background-image: url("data:image/svg+xml,%3Csvg width='17' height='9' viewBox='0 0 17 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12.6391 8.59606C12.5735 8.66535 12.4632 8.66535 12.3976 8.59606C12.337 8.53201 12.337 8.43179 12.3976 8.36774L16.0577 4.5L12.3976 0.632258C12.337 0.568209 12.337 0.467992 12.3976 0.403942C12.4632 0.334648 12.5735 0.334648 12.6391 0.403942L16.5153 4.5L12.6391 8.59606Z' fill='black'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M15.573 4.5L12.1418 0.874121C11.9527 0.674373 11.9527 0.361828 12.1418 0.162079C12.3463 -0.0540261 12.6904 -0.0540267 12.8949 0.162079L17 4.5L12.8949 8.83792C12.6904 9.05403 12.3463 9.05403 12.1418 8.83792C11.9527 8.63817 11.9527 8.32563 12.1418 8.12588L15.573 4.5Z' fill='black'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0 4.5C0 4.22386 0.223858 4 0.5 4H15.5C15.7761 4 16 4.22386 16 4.5C16 4.77614 15.7761 5 15.5 5H0.5C0.223858 5 0 4.77614 0 4.5Z' fill='black'/%3E%3C/svg%3E%0A");
        }
      }
    }

    // Контент слайда
    &-slide {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      row-gap: 10px;
      min-width: 0;

      background: white;
      box-shadow: 0px 5px 20px 0px rgba(black, 0.1);
      border-radius: 8px;
      padding: 40px;

      @media (max-width: $brTablet) {
        padding: 20px;
      }

      &-text {
        font-weight: 300;

        // @media (max-width: $brMobile) {
        //   font-size: 14px;
        // }
      }

      &-label {
        text-align: right;
        font-weight: 600;
      }
    }

    // &-pagination {
    // }
  }
}
</style>
