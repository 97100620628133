<template>
  <div>
    <button :class="classes" role="button" type="button" :title="title" :data-popup="targetPopup" v-on:click="openPopup" style="display: none">
      {{ text }}
    </button>
  </div>
</template>

<script>
import global from "../functions.js";

export default {
  name: "AppButtonPopup",
  props: {
    classes: {
      type: String,
      default: "feedback-button",
    },
    targetPopup: {
      type: String,
      default: "#popup-feedback",
    },
    text: {
      type: String,
      default: "Оставить заявку",
    },
    title: {
      type: String,
      default: "Открыть форму",
    },
  },
  methods: {
    openPopup(event) {
      global.methods.classAction(
        {
          selectors: ["html", "body"],
          classes: "locked",
        },
        {
          selectors: event.target.getAttribute("data-popup"),
          classes: "activated",
        }
      );
    },
  },
};
</script>

<style lang="scss">
// Кнопка для открытия формы обратной связи
.feedback-button {
  width: 240px;
  height: 48px;
  background: $colorMain;
  border-radius: 8px;

  font-weight: 700;
  font-size: 20px;

  color: white;

  &:hover {
    background: $colorMainHover;
  }

  @media (max-width: $brTabletSm) {
    max-width: 100%;
  }
}

.order-button {
  background: #6e65c1;
  border-radius: 8px;
  width: 100%;
  height: 40px;
  font-weight: 700;
  font-size: 16px;
  color: white;

  &:hover {
    background: $colorMainHover;
  }
}
</style>
