<template>
  <footer class="footer">
    <div class="container footer-container">
      <div class="footer-main">
        <div>
          <AppLogo />
        </div>
        <AppFooterMenu />

        <div class="footer-contacts">
          <AppButtonPopup />
          <a href="tel:+7 922 628 7911" class="footer-tel" title="Перейти к набору номера"> +7 922 628 7911 </a>
          <div class="messengers footer-messengers">
            <a href="tg://msg?to=+79226287911" target="_blank" rel="noopener noreferrer" class="messengers-link" title="Напишите нам в Телеграм">
              <img src="./telegram.svg" alt="telegram image" />
            </a>
            <a href="https://wa.me/79228296911" target="_blank" rel="noopener noreferrer" class="messengers-link" title="Напишите нам в What's App">
              <img src="./whatsapp.svg" alt="whatsapp image" />
            </a>
            <a href="viber://chat/number=+7 922 628 7911" target="_blank" rel="noopener noreferrer" class="messengers-link" title="Напишите нам в Viber">
              <img src="./viber.svg" alt="viber image" />
            </a>
          </div>
        </div>
      </div>
      <div class="footer-bottom" role="contentinfo">
        <!-- <a href="#" title="Ознакомиться с политикой конфиденциальности">
          Политика конфиденциальности
        </a> -->
        <router-link to="/policy" title="Ознакомиться с политикой конфиденциальности"> Политика конфиденциальности </router-link>
        <a href="http://cdlw.ru" target="_blank" rel="noopener noreferrer" title="Посетить сайт разработчика"> Создание и продвижение сайта: cdlw.ru </a>
      </div>
    </div>
  </footer>
</template>

<script>
// Импортируем логотип
import AppLogo from "./AppLogo.vue";
// Импортируем кнопку для формы обратной связи
import AppButtonPopup from "./AppButtonPopup.vue";
// Импортируем меню для футера
import AppFooterMenu from "./AppFooterMenu.vue";

export default {
  name: "AppFooter",
  components: {
    AppLogo,
    AppButtonPopup,
    AppFooterMenu,
  },
};
</script>

<style lang="scss">
.footer {
  margin-top: 160px;
  background: white;
  box-shadow: 0px -30px 30px rgba(0, 0, 0, 0.05);

  a {
    &:hover {
      color: $colorMain;
    }
  }

  &-main {
    padding: 80px 0;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    @media (max-width: $brTabletSm) {
      // flex-direction: column;
      // justify-content: flex-start;
      // row-gap: 20px;

      padding: 40px 0;
    }
  }

  // &-group {
  //   display: flex;
  //   // align-items: center;
  //   column-gap: 80px;
  // }

  // .navigation-menu {
  //   align-items: flex-start;

  //   &-item {
  //     padding: 0;
  //     &::after {
  //       display: none;
  //     }
  //   }

  //   &-sublist {
  //     margin: 20px 0 0 0;
  //     // display: none;
  //     position: static;
  //     opacity: 1;
  //     visibility: visible;
  //     transform: none;
  //     box-shadow: none;
  //     background: none;
  //     padding: 0;
  //     width: auto;
  //   }
  // }

  &-contacts {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    row-gap: 20px;

    @media (max-width: $brTabletSm) {
      align-items: flex-start;
    }

    // text-align: right;
  }

  &-tel {
    font-weight: 500;
    font-size: 20px;
    &:hover {
      color: $colorMain;
    }
  }

  &-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 40px 0;
    border-top: 1px solid $colorGrey;

    font-size: 18px;

    @media (max-width: $brTabletSm) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      row-gap: 10px;
      padding: 20px 0 80px 0;
      font-size: 16px;
    }
  }
}
</style>
