<template>
  <div class="pseudo-select">
    <div class="pseudo-select-current" v-on:click="togglePseudoSelect">
      <div class="pseudo-select-arrow-decor"></div>
      <input type="text" class="pseudo-select-value" :value="inputValue" required />
      <span class="pseudo-select-placeholder" :data-placeholder="placeholder" v-show="!inputValue">
        {{ placeholder }}
      </span>
    </div>
    <div class="pseudo-select-list" role="list">
      <label class="pseudo-select-list-item" v-for="(way, index) of ways" :key="index" role="option">
        <input name="prefer-connection" type="radio" class="pseudo-select-list-item-input" :value="way" v-model="inputValue" hidden v-on:click="closePseudoSelect" />
        <span class="pseudo-select-list-item-text">
          {{ way }}
        </span>
      </label>
    </div>
  </div>
  <div class="pseudo-select-note" style="font-size: 12px; font-weight: 300" v-if="hasNote">
    {{ noteText }}
  </div>
</template>

<script>
export default {
  name: "AppPseudoSelect",
  props: {
    placeholder: {
      type: String,
      default: "Предпочитаемый способ связи",
    },
    // required: {
    //   type: Boolean,
    //   default: true,
    // },
    hasNote: {
      type: Boolean,
      default: true,
    },
    noteText: {
      type: String,
      default: "Свяжемся с Вами в рабочее время с 10:00 до 17:00.",
    },
  },
  data: () => ({
    inputValue: "",
    ways: ["Звонок на телефон", "What's App", "Telegram", "Viber"],
  }),
  methods: {
    togglePseudoSelect(event) {
      let current = {
        // current: event.target.closest(".pseudo-select-current"),
        select: event.target.closest(".pseudo-select"),
      };

      current.select.classList.toggle("activated");
    },
    closePseudoSelect(event) {
      let current = {
        select: event.target.closest(".pseudo-select"),
      };
      setTimeout(() => {
        current.select.classList.remove("activated");
      }, 50);
    },
  },
};
</script>

<style lang="scss">
// Кастомные селекты
.pseudo-select {
  width: 100%;
  position: relative;

  &.activated {
    // Когда селект открыт
    .pseudo-select {
      &-current {
        border-radius: 8px 8px 0 0;
      }
      &-placeholder {
        opacity: 0;
      }
      &-list {
        z-index: 100;
        transform: none;
        pointer-events: auto;
        opacity: 1;
        visibility: visible;

        border-radius: 0 0 8px 8px;
      }
      &-arrow-decor {
        transform: rotate(180deg);
      }
    }
  }

  &-current {
    position: relative;

    background: $colorMinor;
    border-radius: 8px;
    width: 100%;
    height: 48px;
    // padding: 0 20px;

    // display: flex;
    // align-items: center;

    transition: $transitionDefault;

    &:hover {
      cursor: pointer;
    }
  }

  &-arrow-decor {
    position: absolute;
    z-index: 10;
    top: 0;
    right: 20px;
    width: 14px;
    height: 100%;

    transition: $transitionDefault;

    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg width='14' height='8' viewBox='0 0 14 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1.18125 1.49629C1.08281 1.40319 1.08281 1.2465 1.18125 1.15339C1.27224 1.06733 1.4146 1.06733 1.50559 1.15339L6.99998 6.35032L12.4944 1.15339C12.5854 1.06733 12.7277 1.06733 12.8187 1.15339C12.9172 1.2465 12.9172 1.40319 12.8187 1.49629L6.99998 7L1.18125 1.49629Z' fill='black'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6.99998 5.66209L12.1508 0.79014C12.4346 0.521746 12.8785 0.521746 13.1623 0.790139C13.4693 1.08051 13.4693 1.56917 13.1623 1.85954L6.99998 7.68823L0.837666 1.85954C0.530673 1.56917 0.530674 1.08051 0.837666 0.79014C1.12142 0.521747 1.56541 0.521745 1.84917 0.79014L6.99998 5.66209Z' fill='black'/%3E%3C/svg%3E%0A");
  }

  &-placeholder,
  &-value {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0 20px;
    display: flex;
    align-items: center;

    transition: $transitionDefault;
    font-size: 16px;
    font-weight: 400;
  }

  &-placeholder {
    color: #888888;
  }

  &-value {
    pointer-events: none;
  }

  &-list {
    transition: $transitionDefault;
    transform: translate(0, 20px);
    pointer-events: none;
    opacity: 0;
    visibility: hidden;

    position: absolute;
    z-index: 10;
    top: 100%;
    left: 0;
    width: 100%;

    padding: 20px;
    background: $colorMinor;
    box-shadow: 0px 15px 20px 0px rgba(black, 0.1);
    border-radius: 8px;

    display: flex;
    flex-direction: column;
    row-gap: 10px;

    &-item {
      display: flex;
      align-items: center;
      column-gap: 10px;

      &:hover {
        cursor: pointer;
      }

      input {
        width: 20px;
        height: 20px;
        background: white;
      }
    }
  }

  &-calculator {
    .pseudo-select-list-item-input {
      width: 40px;
      height: 20px;
      border-radius: 8px;
    }
  }
}
</style>
