// Используем строгий режим
"use strict";

// Экспортируем функции
export default {
  name: "global",
  methods: {
    classAction: (...optionsObjects) => {
      // Перебираем массив
      optionsObjects.forEach((options) => {
        // Получаем селекторы и перебираем все элементы на странице
        document.querySelectorAll(options.selectors).forEach((element) => {
          // Если классы нужно убрать
          if (options.action == "remove") {
            element.classList.remove(options.classes);
          }
          // Если классы нужно заменить
          else if (options.action == "toggle") {
            element.classList.toggle(options.classes);
          }
          // По умолчанию классы добавляются
          else {
            element.classList.add(options.classes);
          }
        });
      });
    },
  },
};
