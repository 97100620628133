<template>
  <nav role="navigation" class="navigation">
    <div class="container navigation-container">
      <AppLogo />

      <div class="navigation-group" v-if="!isTablet">
        <AppNavigationMenu />
        <AppButtonPopup />
      </div>

      <button role="button" type="button" class="navigation-mobile-button" v-on:click="openMenu">
        <img src="./icon-menu-button.svg" alt="Навигация" />
      </button>
    </div>
  </nav>
  <nav role="navigation" class="navigation-mobile" v-if="isTablet">
    <AppNavigationMenu />
    <!-- <AppButtonPopup /> -->
  </nav>
</template>

<script>
// Импортируем логотип
import AppLogo from "./AppLogo.vue";

// Импортируем навигационное меню
import AppNavigationMenu from "./AppNavigationMenu.vue";

// Импортируем кнопку открытия формы
import AppButtonPopup from "./AppButtonPopup.vue";

// Импортируем глобальные методы
import global from "../functions.js";

export default {
  name: "AppNavigation",
  components: {
    AppLogo,
    AppNavigationMenu,
    AppButtonPopup,
  },
  data: () => ({
    // Определяем ширину экрана
    isTablet: window.matchMedia("(max-width:1199.5px)").matches,
  }),
  // updated() {
  //   this.$nextTick(function () {
  //     this.isTablet = window.matchMedia("(max-width:1199.5px)").matches;
  //   });
  // },
  // created() {
  //   this.isTablet = window.matchMedia("(max-width:1199.5px)").matches;
  // },
  methods: {
    openMenu() {
      // Глобальный метод
      global.methods.classAction(
        {
          selectors: ["html", "body"],
          classes: "locked",
        },
        {
          selectors: [".navigation-mobile", "#overlay-navigation"],
          classes: "activated",
        }
      );
    },
  },
};

document.addEventListener("click", function (event) {
  if (event.target.closest("a")) {
    global.methods.classAction(
      {
        selectors: ["html", "body"],
        classes: "locked",
        action: "remove",
      },
      {
        selectors: [".navigation-mobile", "#overlay-navigation"],
        classes: "activated",
        action: "remove",
      }
    );
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
// Навигация
.navigation {
  background: white;
  box-shadow: 0px 5px 20px 0px rgba(black, 0.1);
  // padding: 20px 0;

  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  height: 88px;
  width: 100vw;

  .navigation-container {
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-group {
    display: flex;
    align-items: center;
    column-gap: 80px;

    @media (max-width: $brTablet) {
      display: none;
    }
  }

  &-mobile {
    display: none;

    // Общие стили
    position: fixed;
    z-index: 120;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;

    max-width: 480px;

    row-gap: 40px;
    flex-direction: column;

    transform: translate(-125%, 0);
    // opacity: 0;
    pointer-events: none;
    transition: $transitionDefault;

    padding: 20px 20px 80px 20px;
    background: white;
    box-shadow: 10px 10px 40px 0px rgba(black, 0.25);
    // border-top: 1px solid $colorGrey;

    &.activated {
      transform: none;
      pointer-events: auto;
    }

    @media (max-width: $brTablet) {
      display: flex;
    }

    @media (max-width: $brTabletMd) {
      max-width: 400px;
    }

    @media (max-width: $brMobile) {
      max-width: 280px;
      padding: 20px 16px 80px 16px;
    }

    @media (max-width: $brMobileSm) {
      max-width: 260px;
    }

    &-button {
      display: none;

      @media (max-width: $brTablet) {
        display: flex;
      }
    }
  }
}
</style>
