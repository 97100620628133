<template>
  <div id="popups">
    <div class="overlay" id="popup-feedback" title="Закрыть окно" style="z-index: 130">
      <div class="container overlay-container" title="Закрыть окно">
        <AppFormFeedback :hasClosingButton="true" />
      </div>
    </div>
    <div class="overlay" id="popup-order" title="Закрыть окно" style="z-index: 130">
      <div class="container overlay-container" title="Закрыть окно">
        <AppFormOrder :hasClosingButton="true" />
      </div>
    </div>
    <div class="overlay" id="overlay-navigation" v-on:click="closeMenu"></div>
  </div>
</template>

<script>
import global from "../functions.js";

import AppFormFeedback from "./AppFormFeedback.vue";
import AppFormOrder from "./AppFormOrder.vue";
export default {
  name: "AppPopups",
  components: {
    AppFormFeedback,
    AppFormOrder,
  },
  methods: {
    closeMenu() {
      global.methods.classAction(
        {
          selectors: ["html", "body"],
          classes: "locked",
          action: "remove",
        },
        {
          selectors: [".navigation-mobile", "#overlay-navigation"],
          classes: "activated",
          action: "remove",
        }
      );
    },
  },
};

document.addEventListener("click", function (event) {
  // Закрывать всплывающие окна при клике вне его
  if (event.target.classList.contains("overlay") || event.target.classList.contains("overlay-container")) {
    global.methods.classAction({ selectors: ["html", "body"], classes: "locked", action: "remove" }, { selectors: ".overlay", classes: "activated", action: "remove" });
  }
});

// Закрывать всплывающие окна при нажатии клавиши Escape
document.addEventListener("keydown", function (event) {
  // Если нажали клавишу ESC
  if (event.code === "Escape") {
    global.methods.classAction({ selectors: ["html", "body"], classes: "locked", action: "remove" }, { selectors: ".overlay", classes: "activated", action: "remove" });
  }
});
</script>

<style></style>
