<template>
  <router-link :to="{ name: 'Главная страница' }" class="navigation-logo logo" title="На главную страницу">
    <img alt="логотип" src="logo.svg" />
  </router-link>
</template>

<script>
export default {
  name: "AppLogo",
};
</script>

<style scoped lang="scss">
.logo {
  width: 160px;
  height: 48px;
  // background: $colorGrey;
  border-radius: 8px;

  @extend %flex-center-center;

  img {
    border-radius: 8px;
    width: 100%;
    height: 100%;
  }
}
</style>
