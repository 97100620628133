import { createStore } from "vuex";

// Импортируем базу данных
import databaseJson from "./database.json";

// Резервируем объект с массивами для каталога
let arrays = {
  allProducts: [],
  allSuggestedProducts: [],
  allNewProducts: [],
};

// Формируем массив из всех продуктов
databaseJson.catalog.groups.forEach((group) => {
  // Если в группе есть продукты
  if (group.products) {
    // Собираем все продукты в один массив
    arrays.allProducts = arrays.allProducts.concat(group.products);
  }
});

// Формируем массив из лучших / рекомендуемых продуктов
databaseJson.catalog.groups.forEach((group) => {
  // Если в группе есть продукты
  if (group.products) {
    // Проходимся циклом по всем продуктам
    group.products.forEach((product) => {
      // Если продукт нужно предлагать
      if (product.suggest == true || product.new == true) {
        // Собираем все продукты в один массив
        arrays.allSuggestedProducts = arrays.allSuggestedProducts.concat(product);
      }
    });
  }
});

export default createStore({
  state: () => {
    return {
      favorites: 0,
      database: databaseJson,
      allProducts: arrays.allProducts,
      allSuggestedProducts: arrays.allSuggestedProducts,
      // allNewProducts: arrays.allNewProducts,
      header: databaseJson.header,
    };
  },
  getters: {
    favorites(state) {
      return state.favorites;
    },
  },
  mutations: {
    countFavorites(state) {
      state.favorites += 1;
    },
  },
  actions: {
    checkForFavorites() {
      document.querySelectorAll("[data-id]").forEach((element) => {
        element.classList.remove("activated");
      });

      let array = localStorage.getItem("favorite-products").split(",");
      array.forEach((element) => {
        document.querySelector(`[data-id='${element}']`).classList.add("activated");
      });
    },
  },
  modules: {},
});
