<template>
  <header role="banner" class="header">
    <swiper
      :class="'header-slider'"
      :modules="modules"
      :slides-per-view="slidesPerView"
      :space-between="spaceBetween"
      :loop="loop"
      :speed="speed"
      :pagination="pagination"
      :slide-to-clicked-slide="slideToClickedSlide"
      :preload-images="preloadImages"
      :lazy="lazy"
      watch-slides-progress
      watch-slides-visibility
    >
      <template v-slot:wrapper-start>
        <template v-for="(slide, index) of $store.state.header" :key="index">
          <swiper-slide>
            <div class="header-slider-slide">
              <img alt="фон" class="header-slider-slide-bg" :src="slide.img" />

              <div class="header-slider-slide-thumb">
                <div class="container header-slider-slide-thumb-container">
                  <h2 class="header-slider-slide-title">{{ slide.title }}</h2>
                  <div class="header-slider-slide-description">{{ slide.description }}</div>
                  <router-link to="/catalog/" title="Перейти в каталог" class="header-slider-slide-link" data-animate>Перейти в каталог</router-link>
                </div>
              </div>
            </div>
          </swiper-slide>
        </template>
      </template>
      <template v-slot:container-end>
        <div class="swiper-pagination header-slider-pagination"></div>
      </template>
    </swiper>
  </header>
</template>

<script>
// @beforeTransitionStart="animateStart"
// @slideChangeTransitionEnd="animateFinish"

// Импортируем модули слайдера
import { A11y, Lazy, Pagination } from "swiper";
// Импортируем слайдер
import { Swiper, SwiperSlide } from "swiper/vue";
// Импортируем стили слайдера
import "swiper/scss";
import "swiper/scss/lazy";
import "swiper/scss/pagination";

export default {
  name: "AppHeader",
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    return {
      // Общие настройки для обоих слайдеров
      modules: [A11y, Lazy, Pagination],
      loop: true,
      speed: 334,
      slidesPerView: 1,
      spaceBetween: 0,
      slideToClickedSlide: true,
      pagination: {
        el: ".header-slider-pagination",
        clickable: true,
      },
      preloadImages: true,
      lazy: {
        loadOnTransitionStart: false,
        loadPrevNext: false,
      },
    };
  },
};
</script>

<style lang="scss">
[data-animate] {
  transition: 0.667s;
  transform-origin: 0 0;
}

.header {
  margin: (-160px + 88px) 0 80px 0;

  &-slider {
    position: relative;

    &-slide {
      position: relative;

      &-bg {
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      &-thumb {
        width: 100%;
        height: 100%;

        background: linear-gradient(225deg, transparent 0 50%, rgba($colorGrey, 0.85) 0% 100%);

        @extend %flex-center-center;

        padding: 180px 0 140px;

        @media (max-width: $brTablet) {
          background: linear-gradient(225deg, transparent 0 40%, rgba($colorGrey, 0.85) 0% 100%);
        }

        @media (max-width: $brTabletMd) {
          align-items: flex-end;
          padding: 160px 0 120px 0;
        }

        @media (max-width: $brTabletSm) {
          background: linear-gradient(225deg, transparent 0 35%, rgba($colorGrey, 0.75) 0% 100%);
        }

        @media (max-width: $brMobile) {
          background: linear-gradient(225deg, transparent 0 30%, rgba($colorGrey, 0.75) 0% 100%);
          padding: 120px 0 80px 0;
        }

        @media (max-width: $brMobileSm) {
          background: linear-gradient(225deg, transparent 0 25%, rgba($colorGrey, 0.7) 0% 100%);
        }

        &-container {
          display: flex;
          flex-direction: column;
          row-gap: 20px;

          @media (max-width: $brTabletSm) {
            row-gap: 10px;
          }
        }
      }

      &-title {
        font-weight: 500;
        margin: 0;
        max-width: 40%;

        @media (max-width: $brTablet) {
          max-width: 55%;
        }

        @media (max-width: $brTabletSm) {
          font-size: 24px;
        }

        @media (max-width: $brMobile) {
          // font-size: 20px;
          font-weight: 600;
          max-width: 75%;
        }

        @media (max-width: $brMobileSm) {
          font-size: 20px;
        }
      }

      &-description {
        // font-weight: 500;
        max-width: 40%;
        margin: 0 0 20px 0;

        @media (max-width: $brTablet) {
          max-width: 50%;
        }

        @media (max-width: $brTabletSm) {
          margin-bottom: 10px;

          font-size: 14px;
          max-width: 62.5%;
        }

        @media (max-width: $brMobile) {
          font-size: 13px;
          max-width: 72.5%;
        }

        @media (max-width: $brMobileSm) {
          max-width: 80%;
        }
      }

      &-link {
        @extend %flex-center-center;
        background: white;
        border-radius: 8px;

        height: 48px;
        width: 240px;

        font-size: 18px;
        font-weight: 600;

        @media (max-width: $brMobile) {
          font-size: 16px;
          height: 40px;
          width: 220px;
        }
      }
    }
  }
}

html {
  .header-slider {
    .header-slider-pagination {
      position: absolute;
      bottom: 10px;
      height: 48px;
      margin: auto auto 0 auto;
      width: fit-content;

      .swiper-pagination-bullet {
        $size: 20px;

        width: $size;
        height: $size;
        background: transparent;
        // background: rgba($colorGrey, 0.1);
        border: 2px solid $colorMain;
        border-radius: $size;

        &-active {
          width: $size * 3;
          // background: $colorMain;
        }
      }
    }
  }
}
</style>
