<template>
  <AppNavigation />
  <!-- <AppBreadcrumbs /> -->
  <main role="main">
    <router-view> </router-view>
  </main>
  <AppFooter />
  <AppPopups />
</template>

<script>
import AppNavigation from "./components/AppNavigation.vue";
import AppFooter from "./components/AppFooter.vue";
import AppPopups from "./components/AppPopups.vue";
// import AppBreadcrumbs from "./components/AppBreadcrumbs.vue";

export default {
  name: "App",
  components: {
    AppNavigation,
    AppFooter,
    AppPopups,
    // AppBreadcrumbs,
  },
};
</script>
<style lang="scss">
// Импортируем переменные и вспомогательные классы
@import "./components/app/vars.scss";
// Импортируем сброс стилей
@import "./components/app/restart.scss";
// Импортируем сетку
@import "./components/app/grid.scss";
// Импортируем локальные шрифты
@import "./components/app/fonts.scss";
// Импортируем основные стили приложения
@import "./components/app/app.scss";
</style>
