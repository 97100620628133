<template>
  <template v-for="(product, index) of $store.state.allProducts" :key="index">
    <template v-if="$route.params.id == product.id">
      <section>
        <div class="container">
          <template v-if="isTablet">
            <h1 class="catalog-detail-title">{{ product.name }}</h1>
            <div class="catalog-detail-description">
              <template v-if="product.descriptionFull">
                {{ product.descriptionFull }}
              </template>
              <template v-else>
                {{ product.description }}
              </template>
            </div>
          </template>

          <div class="catalog-detail-layout">
            <div class="catalog-detail-sliders">
              <div class="catalog-detail-slider-note">Нажмите на изображение для перехода в полноэкранный режим</div>
              <div class="catalog-detail-slider">
                <swiper
                  :modules="modules"
                  navigation
                  watch-slides-progress
                  watch-slides-visibility
                  mousewheel
                  slide-to-clicked-slide
                  :lazy="lazy"
                  :slides-per-view="sliderMain.slidesPerView"
                  :space-between="sliderMain.spaceBetween"
                  :thumbs="{ swiper: thumbsSwiper }"
                >
                  <template v-slot:wrapper-start>
                    <template v-for="(img, index) of product.imgs" :key="index">
                      <swiper-slide>
                        <div class="swiper-lazy-preloader"></div>
                        <a :href="img.src" :data-fancybox="product.id" :data-caption="product.name" title="Открыть полное изображение">
                          <img src="./thumb.jpg" :alt="product.name" class="swiper-lazy catalog-detail-slider-img" :data-src="img.src" />
                        </a>
                      </swiper-slide>
                    </template>
                  </template>
                </swiper>
              </div>
              <div class="catalog-detail-slider-thumbs">
                <swiper
                  :modules="modules"
                  watch-slides-progress
                  watch-slides-visibility
                  slide-to-clicked-slide
                  :lazy="lazy"
                  mousewheel
                  :scrollbar="sliderThumbs.scrollbar"
                  :slides-per-view="sliderThumbs.slidesPerView"
                  :space-between="sliderThumbs.spaceBetween"
                  @swiper="setThumbsSwiper"
                  :direction="sliderThumbs.direction"
                >
                  <template v-slot:wrapper-start>
                    <template v-for="(img, index) of product.imgs" :key="index">
                      <swiper-slide>
                        <img src="./thumb.jpg" :data-src="img.src" class="swiper-lazy" :alt="product.name" />
                        <div class="swiper-lazy-preloader"></div>
                      </swiper-slide>
                    </template>
                  </template>

                  <template v-slot:container-start>
                    <div style="position: static; margin-bottom: 20px; width: 100%" class="swiper-scrollbar catalog-detail-slider-thumbs-scrollbar"></div>
                  </template>
                </swiper>
              </div>
            </div>

            <div class="catalog-detail-information">
              <template v-if="!isTablet">
                <h1 class="catalog-detail-title">{{ product.name }}</h1>
                <div class="catalog-detail-description">
                  <template v-if="product.descriptionFull">
                    {{ product.descriptionFull }}
                  </template>
                  <template v-else>
                    {{ product.description }}
                  </template>
                </div>
              </template>

              <ul class="catalog-detail-parameters-list">
                <li class="catalog-detail-parameters-item" v-if="product.colors">
                  <div class="catalog-detail-parameters-name">Доступные цвета:</div>
                  <div class="catalog-detail-parameters-value">{{ product.colors }}</div>
                </li>

                <li class="catalog-detail-parameters-item" v-if="product.material">
                  <div class="catalog-detail-parameters-name">Материал:</div>
                  <div class="catalog-detail-parameters-value">{{ product.material }}</div>
                </li>
                <li class="catalog-detail-parameters-item" v-if="product.durability">
                  <div class="catalog-detail-parameters-name">Срок службы:</div>
                  <div class="catalog-detail-parameters-value">{{ product.durability }}</div>
                </li>
                <li class="catalog-detail-parameters-item" v-if="product.size">
                  <div class="catalog-detail-parameters-name">Размеры:</div>
                  <div class="catalog-detail-parameters-value">{{ product.size }}</div>
                </li>
                <li class="catalog-detail-parameters-item">
                  <div class="catalog-detail-parameters-name">Производство:</div>
                  <div class="catalog-detail-parameters-value">Россия</div>
                </li>
                <li class="catalog-detail-parameters-item" v-if="product.minimal">
                  <div class="catalog-detail-parameters-name">Минимальный заказ:</div>
                  <div class="catalog-detail-parameters-value">{{ product.minimal }}</div>
                </li>
                <li class="catalog-detail-parameters-item">
                  <div class="catalog-detail-parameters-name">Цена (за 1 ед.):</div>
                  <div class="catalog-detail-parameters-value">
                    от
                    <span>{{ product.price }}</span> ₽
                  </div>
                </li>
              </ul>

              <div class="catalog-detail-warning">В связи с текущей геополитической ситуацией цепочки поставок могут не стабильны. Актуальные цены и наличие товара на складе можно уточнить по телефону или в мессенджерах.</div>
              <div class="catalog-detail-buttons">
                <router-link to="/contacts" title="Перейти в контакты" class="catalog-detail-link">Перейти в контакты</router-link>

                <AppButtonPopup :classes="'catalog-detail-order-button order-button'" :targetPopup="'#popup-order'" :text="'Заказать'" :title="'Оставить заявку на ' + product.name" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <AppCalculatePrice :product="product" />
    </template>
  </template>

  <section>
    <div class="container">
      <div class="catalog-detail-shipping">
        <h3>Опт и скидки</h3>
        <p>Для всех товаров действуют скидка при оплате за наличные, а также скидки для малого и крупного опта.</p>
        <div class="row" style="column-gap: 40px">
          <router-link to="/wholesale" title="Подробнее об условиях" class="catalog-detail-shipping-link">Подробнее</router-link>
        </div>
      </div>
      <div class="catalog-detail-shipping">
        <h3>Доставка</h3>
        <p>Частными транспортными компаниями либо самовывоз из нашего склада.</p>
        <div class="row" style="column-gap: 40px">
          <router-link to="/contacts#map" title="Перейти к карте" class="catalog-detail-shipping-link">Карта</router-link>
        </div>
      </div>
    </div>
  </section>

  <AppCatalogSuggested />
</template>

<script>
import { ref } from "vue";

// Импортируем модули слайдера
import { A11y, Lazy, Navigation, Mousewheel, Thumbs, Scrollbar } from "swiper";
// Импортируем слайдер
import { Swiper, SwiperSlide } from "swiper/vue";
// Импортируем стили слайдера
import "swiper/scss";
import "swiper/scss/lazy";
import "swiper/scss/navigation";
// import "swiper/scss/pagination";
import "swiper/scss/scrollbar";

import { Fancybox } from "@fancyapps/ui/dist/fancybox.umd.js";
import "@fancyapps/ui/dist/fancybox.css";

Fancybox.bind("[data-fancybox]", {
  infinite: true,
  animated: true,
  groupAll: false,
  Hash: false,
  Image: {
    Panzoom: {
      zoomFriction: 0.8,
      maxScale: function () {
        return 2;
      },
    },
  },
  // Локализация
  l10n: {
    CLOSE: "Закрыть окно",
    NEXT: "Следующий слайд",
    PREV: "Предыдущий слайд",
    MODAL: "Нажмите ESC, чтобы закрыть это окно",
    ERROR: "Что-то пошло не так. Попробуйте позднее.",
    IMAGE_ERROR: "Изображение не найдено.",
    ELEMENT_NOT_FOUND: "HTML Element Not Found",
    AJAX_NOT_FOUND: "Error Loading AJAX : Not Found",
    AJAX_FORBIDDEN: "Error Loading AJAX : Forbidden",
    IFRAME_ERROR: "Error Loading Page",
  },
  Toolbar: {
    display: [
      { id: "prev", position: "center" },
      { id: "counter", position: "center" },
      { id: "next", position: "center" },
      // "zoom",
      //"slideshow",
      //"fullscreen",
      //"download",
      //"thumbs",
      "close",
    ],
  },
});

import AppButtonPopup from "./AppButtonPopup.vue";

import AppCalculatePrice from "./AppCalculatePrice.vue";

import AppCatalogSuggested from "./AppCatalogSuggested.vue";

export default {
  name: "AppCatalogDetail",
  components: {
    Swiper,
    SwiperSlide,
    AppButtonPopup,
    AppCalculatePrice,
    AppCatalogSuggested,
  },
  setup() {
    const thumbsSwiper = ref(null);
    const setThumbsSwiper = (swiper) => {
      thumbsSwiper.value = swiper;
    };
    return {
      Thumbs,
      thumbsSwiper,
      setThumbsSwiper,

      // Общие настройки для обоих слайдеров
      modules: [A11y, Lazy, Navigation, Thumbs, Mousewheel, Scrollbar],
      lazy: {
        loadOnTransitionStart: false,
        loadPrevNext: false,
      },

      // Только для большого слайдера
      sliderMain: {
        slidesPerView: 1,
        spaceBetween: 40,
      },
      // Только для маленького слайдера
      sliderThumbs: {
        direction: "horizontal",
        slidesPerView: 3.0,
        spaceBetween: 20,
        scrollbar: {
          el: ".catalog-detail-slider-thumbs-scrollbar",
          draggable: true,
        },
      },
    };
  },
  data: () => ({
    // Определяем ширину экрана
    isTablet: window.matchMedia("(max-width:1023.5px)").matches,
  }),
};
</script>

<style lang="scss" scoped>
.catalog-detail {
  &-layout {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 40px;
    row-gap: 40px;
    margin-bottom: 40px;

    @media (max-width: $brTabletMd) {
      grid-template-columns: 1fr;
    }

    @media (max-width: $brTabletSm) {
      row-gap: 20px;
    }
  }

  &-sliders {
    min-width: 0;
    max-width: 680px;

    @media (max-width: $brLaptop) {
      max-width: 580px;
    }

    @media (max-width: $brTabletMd) {
      max-width: 100vw;
    }
  }

  &-slider {
    min-width: 0;
    margin-bottom: 20px;

    border-radius: 16px;
    overflow: hidden;

    @media (max-width: $brTabletMd) {
      max-width: 75vw;
      margin: 0 auto 20px auto;
    }

    @media (max-width: $brMobile) {
      max-width: 100vw;
    }

    &-note {
      font-size: 12px;
      font-weight: 300;
      margin-bottom: 10px;
    }

    &-img {
      // max-height: 400px;
      // height: 100%;
      object-fit: cover;
      aspect-ratio: 16 / 9;

      // @media (max-width: $brTabletSm) {
      //   max-height: 240px;
      // }
    }

    a,
    img {
      width: 100%;
      height: 100%;
      border-radius: 16px;
    }

    &-thumbs {
      min-width: 0;
      // max-height: 400px;
      // min-width: 250px;
      // padding-bottom: 20px;
      border-radius: 8px;
      overflow: hidden;

      img {
        // max-height: 120px;
        aspect-ratio: 16 / 9;
        height: 100%;
        object-fit: cover;

        width: 100%;
        border-radius: 8px;

        cursor: pointer;

        @media (max-width: $brTabletSm) {
          // max-height: 80px;
        }
      }
    }
  }

  // &-information {
  // }

  &-title {
    font-size: 32px;
    font-weight: 500;
    margin-bottom: 32px;

    @media (max-width: $brTabletSm) {
      font-size: 24px;
      margin-bottom: 16px;
    }

    @media (max-width: $brMobile) {
      margin-bottom: 12px;
    }
  }

  &-description {
    margin-bottom: 32px;

    @media (max-width: $brTabletSm) {
      // font-size: 14px;
      margin-bottom: 24px;
    }

    @media (max-width: $brMobileSm) {
      font-size: 14px;
      margin-bottom: 16px;
    }
  }

  &-parameters {
    &-list {
      border-radius: 8px;
      overflow: hidden;
      margin-bottom: 32px;
    }

    &-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      column-gap: 20px;

      padding: 10px 16px;

      &:nth-child(even) {
        background: $colorMinor;
      }
    }
    &-name {
      // font-size: 16px;
      width: 50%;
    }
    &-value {
      text-align: right;
      // font-size: 18px;
      font-weight: 500;
    }

    &-name,
    &-value {
      @media (max-width: $brMobileSm) {
        font-size: 14px;
      }
    }
  }

  &-warning {
    padding: 16px;
    border: 2px solid $colorMinor;
    background: $colorMinor;
    border-radius: 16px;

    font-size: 14px;
    font-weight: 300;

    margin-bottom: 32px;
  }

  &-buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 80px;

    @media (max-width: $brTabletSm) {
      column-gap: 40px;
    }

    @media (max-width: $brMobile) {
      grid-template-columns: 1fr;
      row-gap: 20px;
    }
  }

  &-order-button,
  &-link {
    width: 100%;
    height: 48px;

    font-size: 16px;

    // @media (max-width: $brMobile) {
    //   height: 40px;
    // }
  }

  &-link {
    font-weight: 600;

    @extend %flex-center-center;

    background: #f5f5f5;
    border-radius: 8px;
  }

  &-order-button {
    &:hover {
      background: $colorMainHover;
    }
  }

  &-shipping {
    margin-bottom: 40px;

    p {
      margin-bottom: 16px;
    }

    &-link {
      width: 240px;
      height: 48px;

      font-weight: 600;
      background: #f5f5f5;
      border-radius: 8px;

      @extend %flex-center-center;
    }
  }
}
</style>
