<template>
  <section class="calculator-section">
    <div class="container calculator-container">
      <h2 class="calculator-section-title">Рассчитайте стоимость онлайн</h2>
      <div class="calculator">
        <div class="calculator-top">
          <div class="form-group">
            <div class="form-label">Количество:</div>
            <input type="number" min="0" max="999" placeholder="Сколько заказать?" class="form-input" v-model="amount" />
          </div>
        </div>
        <div class="calculator-discounts">
          <label class="calculator-label" title="Покупаю как юридическое лицо">
            <input type="checkbox" class="calculator-checkbox" v-model="discounts.business.checked" />
            <span class="calculator-label-text"> Рассчитать для юридического лица </span>
          </label>
          <label class="calculator-label" title="Скидка 8% при оплате наличными">
            <input type="checkbox" class="calculator-checkbox" v-model="discounts.cash.checked" />
            <span class="calculator-label-text"> Рассчет наличными </span>
          </label>
          <label class="calculator-label" title="Скидка 3% при покупке от 150 ед. и 5% при покупке от 300 ед." style="pointer-events: none">
            <input type="checkbox" class="calculator-checkbox" v-model="discounts.wholesale.checked" :checked="amount >= 150 ? true : false" />
            <span class="calculator-label-text"> Оптовая скидка </span>
          </label>
          <div class="calculator-submit-info" style="margin-top: -10px">Оптовая скидка применяется автоматически - 3% при покупке от 150 ед. и 5% при покупке от 300 ед.</div>
        </div>
        <div class="calculator-bottom">
          <div class="calculator-result-row">
            <div class="calculator-result-text">Приблизительная стоимость:</div>
            <div class="row" style="column-gap: 10px">
              <div class="calculator-result-value">
                {{
                  result({
                    amount: amount,
                    discounts: {
                      business: {
                        checked: discounts.business.checked,
                        value: discounts.business.value,
                      },
                      cash: {
                        checked: discounts.cash.checked,
                        value: discounts.cash.value,
                      },
                      wholesale: {
                        checked: discounts.wholesale.checked,
                        value1: discounts.wholesale.value1,
                        value2: discounts.wholesale.value2,
                      },
                    },
                    price: product.price,
                  })
                }}
              </div>
              <div class="calculator-result-currency">₽</div>
            </div>
          </div>

          <div class="calculator-submit-info">Указанная информация носит ознакомительный характер и не является публичной офертой. Наличие и актуальную стоимость узнавайте у продавца.</div>
          <!-- <AppButtonPopup :classes="'calculator-submit-button'" :targetPopup="'#popup-feedback'" :text="'Зафиксировать предложение'" /> -->
          <router-link to="/contacts" class="calculator-submit-button flex-center-center" title="Перейти в контакты">В контакты</router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// import AppButtonPopup from "./AppButtonPopup.vue";
export default {
  name: "AppCalculatePrice",
  components: {
    // AppButtonPopup,
  },
  props: {
    product: {
      type: Object,
    },
  },
  data: () => ({
    amount: 20,
    discounts: {
      business: {
        checked: true,
        value: 1.05,
      },
      cash: {
        checked: false,
        value: 0.92,
      },
      wholesale: {
        checked: false,
        value1: 0.97,
        value2: 0.95,
      },
    },
  }),
  methods: {
    result(options) {
      // Задаем минимальное значение
      if (options.amount <= 0) {
        options.amount = 0;
      }
      // Задачем максимальное значение
      if (options.amount >= 999) {
        options.amount = 999;
      }

      // Рассчитываем результат
      let finalResult = options.amount * options.price;
      // Рассчитываем для юр. лиц
      if (options.discounts.business.checked) {
        finalResult = finalResult * options.discounts.business.value;
      }
      // Рассчитываем скидку за наличные
      if (options.discounts.cash.checked) {
        finalResult = finalResult * options.discounts.cash.value;
      }

      // Рассчитываем оптовую скидку
      if (options.amount >= 150) {
        finalResult = finalResult * options.discounts.wholesale.value1;

        if (options.amount >= 300) {
          finalResult = finalResult * options.discounts.wholesale.value2;
        }
      }
      // Преобразуем в целое число и делим на разряды
      finalResult = parseInt(finalResult).toLocaleString();

      // Выдаем результат
      return finalResult;
    },
  },
};
</script>

<style lang="scss" scoped>
// Рассчет стоимости
.calculator {
  width: 520px;
  padding: 40px;

  background: white;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.15);
  border-radius: 16px;

  display: flex;
  flex-direction: column;
  row-gap: 40px;

  @media (max-width: $brTabletMd) {
    width: 100%;
    padding: 32px 20px;
    row-gap: 20px;
  }

  @media (max-width: $brMobile) {
    padding: 24px 16px;
    row-gap: 16px;
  }

  &-top,
  &-discounts,
  &-bottom {
    display: flex;
    flex-direction: column;
    row-gap: 20px;

    @media (max-width: $brMobile) {
      row-gap: 16px;
    }
  }

  &-bottom {
    border-top: 4px solid $colorMain;
    padding-top: 20px;
  }

  &-submit {
    &-info {
      font-weight: 300;
      font-size: 12px;
    }

    &-button {
      // margin-top: -10px;
      height: 48px;
      width: 100%;
      background: $colorMain;
      border-radius: 8px;
      font-weight: 700;
      font-size: 16px;
      color: white;
      //@extend %flex-center-center;

      &:hover {
        background: $colorMainHover;
      }

      @media (max-width: $brMobile) {
        // height: 40px;
        font-size: 14px;
      }
    }
  }

  &-result {
    &-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &-text {
      font-weight: 500;
      font-size: 16px;

      @media (max-width: $brMobile) {
        font-size: 14px;
      }
    }

    &-value,
    &-currency {
      font-weight: 600;
      font-size: 32px;

      @media (max-width: $brMobile) {
        font-size: 24px;
      }
    }
  }

  &-label {
    width: fit-content;

    display: flex;
    align-items: center;
    column-gap: 10px;

    &:hover {
      cursor: pointer;
    }

    &-text {
      font-weight: 400;
      font-size: 16px;

      @media (max-width: $brMobile) {
        font-size: 14px;
      }
    }
  }

  &-checkbox {
    padding: 0;
    width: 60px;
    height: 32px;

    border-radius: 16px;
    background: $colorGrey;

    // @media (max-width: $brTabletSm) {
    //   width: 48px;
    //   height: 24px;
    // }

    &:checked {
      background: $colorMain;

      &::after {
        transform: translate(28px, -50%);

        // @media (max-width: $brTabletSm) {
        //   transform: translate(24px, -50%);
        // }
      }
    }

    &::after {
      width: 24px;
      height: 24px;
      top: 50%;
      left: 0;
      background: white;
      border-radius: 50%;
      transform: translate(8px, -50%);

      // @media (max-width: $brTabletSm) {
      //   width: 16px;
      //   height: 16px;
      // }
    }
  }
}
</style>
