<template>
  <ul class="footer-menu" role="menu">
    <li class="footer-menu-item" :class="menuItem.submenu ? 'footer-menu-item-has-submenu' : ''" v-for="(menuItem, index) of $store.state.database.menu" :key="index">
      <!-- <template v-if="menuItem.name == 'Избранное'">
        <div class="footer-menu-item-favs">
          {{ $store.state.favorites }}
        </div>
      </template> -->

      <router-link :to="menuItem.link" class="footer-menu-link" :title="menuItem.name">
        {{ menuItem.name }}
      </router-link>

      <!-- <ul class="footer-menu-sublist" v-if="menuItem.submenu">
        <router-link :to="sublink.link" class="footer-menu-sublink" v-for="(sublink, index) of menuItem.submenu" :key="index" :title="sublink.name">
          {{ sublink.name }}
        </router-link>
      </ul> -->
    </li>
  </ul>
</template>

<script>
// Имитируем данные с сервера
// import DataBase from "../../public/database.json";
// const serverData = DataBase;

export default {
  name: "AppNavigationMenu",
  data: () => ({
    // menuItems: serverData.menu,
  }),
};
</script>

<style lang="scss">
.footer-main {
  column-gap: 40px;

  @media (max-width: $brTabletMd) {
    row-gap: 40px;
    flex-wrap: wrap;
  }
}

.footer-menu {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-content: center;

  text-align: center;

  column-gap: 40px;
  row-gap: 10px;

  @media (max-width: $brTablet) {
    column-gap: 20px;
  }

  @media (max-width: $brTabletMd) {
    flex-grow: 1;
    order: -1;
    width: 100%;
  }

  @media (max-width: $brTabletSm) {
    column-gap: 20px;
  }

  @media (max-width: $brMobile) {
    order: 0;
    text-align: left;
    grid-template-columns: repeat(2, 1fr);
  }

  &-link {
    font-size: 20px;

    @media (max-width: $brTablet) {
      font-size: 18px;
      font-weight: 500;
    }
  }

  &-sublist {
    padding: 10px 0 0 0;

    display: flex;
    flex-direction: column;
    row-gap: 5px;

    text-align: left;
  }
}
</style>
