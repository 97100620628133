<template>
  <section class="pnf-section">
    <div class="container">
      <h1 class="pnf-title">404</h1>
      <h2 class="pnf-subtitle">Такой страницы не существует.</h2>

      <div class="pnf-buttons-layout">
        <router-link :to="{ name: 'Главная страница' }" class="pnf-button">Вернуться на главную</router-link>
        <router-link :to="{ name: 'Каталог' }" class="pnf-button pnf-button-colored">Перейти в каталог</router-link>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "PageNotFound",
};
</script>

<style lang="scss" scoped>
.pnf {
  &-section {
  }

  &-title {
    font-size: 240px;
    font-weight: 700;
    text-align: center;
    color: $colorMain;

    margin: 0;

    @media (max-width: $brTabletSm) {
      font-size: 200px;
    }

    @media (max-width: $brMobile) {
      font-size: 160px;
    }

    @media (max-width: $brMobileSm) {
      font-size: 140px;
    }
  }

  &-subtitle {
    text-align: center;
  }

  &-buttons-layout {
    @extend %flex-center-center;

    column-gap: 120px;
    row-gap: 20px;

    @media (max-width: $brTabletSm) {
      column-gap: 40px;
    }

    @media (max-width: $brMobile) {
      flex-wrap: wrap;
    }
  }

  &-button {
    width: 240px;
    height: 48px;

    @extend %flex-center-center;

    border-radius: 8px;
    background: $colorMinor;
    font-weight: 600;

    &-colored {
      background: $colorMain;
      color: white;
      font-weight: 700;
    }
  }
}
</style>
