import {
  createRouter,
  createWebHashHistory,
  // createWebHistory,
} from "vue-router";

import PageCatalog from "./pages/PageCatalog.vue";
import PageCatalogDetail from "./pages/PageCatalogDetail.vue";

import PageIndex from "./pages/PageIndex.vue";
import PagePolicy from "./pages/PagePolicy.vue";
import PageFav from "./pages/PageFav.vue";
import PageWholesale from "./pages/PageWholesale.vue";
import PageContacts from "./pages/PageContacts.vue";

import PageNotFound from "./pages/PageNotFound.vue";

export default createRouter({
  history: createWebHashHistory(),
  // history: createWebHistory(),
  mode: "history",

  routes: [
    {
      path: "/",
      name: "Главная страница",
      component: PageIndex,
    },
    {
      path: "/catalog",
      name: "Каталог",
      component: PageCatalog,
    },
    {
      path: "/catalog/product-:id",
      name: "Страница товара",
      component: PageCatalogDetail,
    },

    { path: "/wholesale", name: "Оптовикам", component: PageWholesale },
    { path: "/fav", name: "Избранное", component: PageFav },
    { path: "/contacts", name: "Контакты", component: PageContacts },

    { path: "/policy", name: "Политика конфиденциальности", component: PagePolicy },

    // Страница 404
    { path: "/:catchAll(.*)", name: "page not found 404", component: PageNotFound },
  ],

  // Настраиваем скролл
  scrollBehavior(to, from, savedPosition) {
    // Если переходим по хэшу
    if (to.hash) {
      return {
        el: to.hash,
        // Высота навигации плюс небольшой отступ
        top: 108,
        behavior: "smooth",
      };
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});
