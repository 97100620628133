<template>
  <form class="form" role="form" title="">
    <button class="form-button-close" type="button" role="button" v-if="hasClosingButton" v-on:click="closePopup" title="Закрыть окно"></button>
    <h2 class="form-title">Оставьте заявку на обратную связь</h2>
    <div class="form-group">
      <div class="form-label">Ваше имя:</div>
      <input type="text" name="user-name" placeholder="Джеймс Хэтфилд" class="form-input" required />
    </div>
    <div class="form-group">
      <div class="form-label">Ваш телефон:</div>
      <input type="tel" name="user-tel" placeholder="В любом формате" class="form-input input-mask-tel" required />
    </div>
    <div class="form-group">
      <div class="form-label">Предпочитаемый способ связи:</div>
      <AppPseudoSelect />
    </div>
    <div class="form-group">
      <div class="form-submit-note">Оставляя заявку на обратную связь, вы даете согласие на хранение и обработку персональных данных.</div>
      <button type="submit" role="button" class="form-submit-button" :class="formValid ? 'form-submit-button-valid' : 'form-submit-button-disabled'" :title="formValid ? 'Отправить форму' : 'Заполните все обязательные поля'">
        Свяжитесь со мной
      </button>
    </div>
  </form>
</template>

<script>
import global from "../functions.js";

import AppPseudoSelect from "./AppPseudoSelect.vue";
export default {
  name: "AppFormFeedback",
  components: { AppPseudoSelect },
  props: { hasClosingButton: { type: Boolean, default: false } },
  data: () => ({
    formValid: false,
  }),
  methods: {
    closePopup(event) {
      event.target.closest(".overlay").classList.remove("activated");

      global.methods.classAction({
        selectors: ["html", "body"],
        classes: "locked",
        action: "remove",
      });
    },
  },
};
</script>

<style lang="scss"></style>
