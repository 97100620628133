<template>
  <template v-if="isTablet">
    <AppLogo />
  </template>

  <ul class="navigation-menu" role="menu" v-if="!isTablet">
    <li class="navigation-menu-item" :class="menuItem.submenu ? 'navigation-menu-item-has-submenu' : ''" v-for="(menuItem, index) of $store.state.database.menu" :key="index">
      <template v-if="menuItem.name == 'Избранное'">
        <div class="navigation-menu-item-favs">
          {{ $store.getters.favorites }}
        </div>
      </template>

      <router-link :to="menuItem.link" class="navigation-menu-link" :title="menuItem.name">
        {{ menuItem.name }}
      </router-link>

      <ul class="navigation-menu-sublist" v-if="menuItem.submenu">
        <router-link :to="sublink.link" class="navigation-menu-sublink" v-for="(sublink, index) of menuItem.submenu" :key="index" :title="sublink.name">
          {{ sublink.name }}
        </router-link>
      </ul>
    </li>
  </ul>
  <ul class="navigation-menu-mobile" role="menu" v-else>
    <li class="navigation-menu-mobile-item" v-for="(menuItem, index) of $store.state.database.menu" :key="index">
      <template v-if="menuItem.name == 'Избранное'">
        <div class="navigation-menu-item-favs navigation-menu-mobile-favs">
          {{ $store.getters.favorites }}
        </div>
      </template>

      <router-link :to="menuItem.link" :title="menuItem.name" class="navigation-menu-mobile-link">
        {{ menuItem.name }}
      </router-link>

      <ul v-if="menuItem.submenu" class="navigation-menu-mobile-sublist">
        <router-link :to="sublink.link" v-for="(sublink, index) of menuItem.submenu" :key="index" :title="sublink.name" class="navigation-menu-mobile-sublink">
          {{ sublink.name }}
        </router-link>
      </ul>
    </li>
  </ul>
</template>

<script>
import AppLogo from "./AppLogo.vue";

export default {
  name: "AppNavigationMenu",
  components: {
    AppLogo,
  },
  data: () => ({
    // Определяем ширину экрана
    isTablet: window.matchMedia("(max-width:1199.5px)").matches,
  }),
  // updated() {
  //   this.$nextTick(function () {
  //     this.isTablet = window.matchMedia("(max-width:1199.5px)").matches;
  //   });
  // },
  // created() {
  //   this.isTablet = window.matchMedia("(max-width:1199.5px)").matches;
  // },
};
</script>

<style lang="scss">
.navigation {
  // &-mobile {
  //   transition: 0.5s;
  // }

  &-menu {
    display: flex;
    align-items: center;
    column-gap: 40px;

    &-item {
      position: relative;
      padding-bottom: 10px;
      margin-bottom: -10px;

      &-favs {
        position: absolute;
        top: 0;
        right: 0;
        width: 24px;
        height: 24px;

        transform: translate(75%, -50%);

        @extend %flex-center-center;

        background: $colorMain;
        border-radius: 50%;
        // padding: 4px;

        font-size: 12px;
        // font-weight: 600;
        color: white;
      }

      &-has-submenu {
        position: relative;
        padding-right: (14px + 10px);
        &::after {
          content: "";
          position: absolute;
          top: 50%;
          // left: 100%;
          right: 0;
          width: 14px;
          height: 8px;

          transform: translate(0, -100%);

          background-position: center center;
          background-size: contain;
          // background: url("../img/icon-arrow-down.svg") no-repeat;
          background-repeat: no-repeat;
          background-image: url("data:image/svg+xml,%3Csvg width='14' height='8' viewBox='0 0 14 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1.18125 1.49629C1.08281 1.40319 1.08281 1.2465 1.18125 1.15339C1.27224 1.06733 1.4146 1.06733 1.50559 1.15339L6.99998 6.35032L12.4944 1.15339C12.5854 1.06733 12.7277 1.06733 12.8187 1.15339C12.9172 1.2465 12.9172 1.40319 12.8187 1.49629L6.99998 7L1.18125 1.49629Z' fill='black'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6.99998 5.66209L12.1508 0.79014C12.4346 0.521746 12.8785 0.521746 13.1623 0.790139C13.4693 1.08051 13.4693 1.56917 13.1623 1.85954L6.99998 7.68823L0.837666 1.85954C0.530673 1.56917 0.530674 1.08051 0.837666 0.79014C1.12142 0.521747 1.56541 0.521745 1.84917 0.79014L6.99998 5.66209Z' fill='black'/%3E%3C/svg%3E%0A");
        }
      }

      &:hover {
        .navigation-menu-sublist {
          transform: none;
          visibility: visible;
          opacity: 1;
          pointer-events: auto;
        }
      }
    }
    &-link {
      // font-weight: 400;
      font-size: 20px;
      // position: relative;

      transition: $transitionDefault;
      &:hover {
        color: $colorMain;
      }
    }

    &-sublist {
      position: absolute;
      z-index: 10;
      top: 100%;
      left: -20px;
      // width: 100%;
      // width: 240px;
      width: 220px;

      background: white;
      box-shadow: 0px 5px 20px 0px rgba(black, 0.1);
      border-radius: 8px;
      padding: 20px;

      transition: $transitionDefault;

      transform: translate(0, 40px);
      visibility: hidden;
      opacity: 0;
      pointer-events: none;

      display: flex;
      flex-direction: column;
      row-gap: 10px;
    }

    &-sublink {
      // font-size: 18px;
      transition: $transitionDefault;
      &:hover {
        color: $colorMain;
      }
    }
  }

  &-menu {
    @media (max-width: $brTablet) {
      display: none;
    }
  }

  &-menu-mobile {
    display: none;

    flex-direction: column;
    row-gap: 12px;
    // margin-bottom: 40px;

    @media (max-width: $brTablet) {
      display: flex;
    }

    &-favs {
      transform: translate(100%, -50%);
    }

    &-item {
      position: relative;
      width: fit-content;
    }

    &-link {
      font-size: 18px;
      font-weight: 500;
      width: fit-content;
    }

    &-sublist {
      display: flex;
      flex-direction: column;
      row-gap: 6px;

      padding: 12px 0 12px 12px;
    }

    &-sublink {
      font-weight: 300;
    }
  }
}
</style>
