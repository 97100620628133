<template>
  <AppCatalog :titleIsH1="true" />
</template>

<script>
import AppCatalog from "../components/AppCatalog.vue";
export default {
  name: "PageCatalog",
  components: { AppCatalog },
};
</script>
