<template>
  <section class="catalog-preview">
    <div class="container catalog-preview-container">
      <h2 class="catalog-preview-title">Каталог</h2>
      <div class="catalog-preview-layout">
        <router-link :to="'/catalog' + '#' + group.id" class="catalog-preview-card" v-for="(group, index) of $store.state.database.catalog.groups" :key="index" :title="group.name">
          <h2 class="catalog-preview-card-title">{{ group.name }}</h2>
          <div class="catalog-preview-card-description">
            {{ group.description }}
          </div>
        </router-link>
      </div>
    </div>
  </section>

  <AppCatalogSuggested />
</template>

<script>
import AppCatalogSuggested from "./AppCatalogSuggested.vue";

export default {
  name: "AppCatalogPreview",
  components: {
    AppCatalogSuggested,
  },
};
</script>

<style lang="scss">
.catalog-preview {
  &-layout {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 40px;

    margin-bottom: 40px;

    @media (max-width: $brTabletMd) {
      row-gap: 40px;
      grid-template-columns: 1fr 1fr;
    }

    @media (max-width: $brTabletSm) {
      column-gap: 20px;
      row-gap: 20px;
    }

    @media (max-width: $brMobile) {
      row-gap: 32px;
      grid-template-columns: 1fr;
    }
  }

  &-card {
    padding: 20px;
    border-radius: 8px;
    // border: 2px solid $colorMinor;
    box-shadow: 0px 5px 20px 0px rgba(black, 0.1);
    transition: $transitionDefault;

    display: flex;
    flex-direction: column;

    background: linear-gradient(45deg, white 0 92.5%, $colorMain 0% 100%);

    &:hover {
      transform: translate(0, -8px);
      background: linear-gradient(45deg, white 0 92.5%, $colorMainHover 0% 100%);
    }

    &-title {
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 10px;

      @media (max-width: $brMobile) {
        font-size: 18px;
      }
    }

    // &-description {
    // }
    &-img {
      max-height: 80px;
      margin: auto 0 0 auto;

      display: none;
    }
  }

  &-link {
    width: fit-content;
    // width: 240px;
    // height: 48px;
    border-radius: 8px;
    // @extend %flex-center-center;

    // color: white;
    font-weight: 500;
    font-size: 24px;
    // background: $colorMain;
    // border: 1px solid $colorGrey;

    &:hover {
      color: $colorMain;

      &::after {
        background-image: url("data:image/svg+xml,%3Csvg width='17' height='9' viewBox='0 0 17 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12.6391 8.59606C12.5735 8.66535 12.4632 8.66535 12.3976 8.59606C12.337 8.53201 12.337 8.43179 12.3976 8.36774L16.0577 4.5L12.3976 0.632258C12.337 0.568209 12.337 0.467992 12.3976 0.403942C12.4632 0.334648 12.5735 0.334648 12.6391 0.403942L16.5153 4.5L12.6391 8.59606Z' fill='%237871c8'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M15.573 4.5L12.1418 0.874121C11.9527 0.674373 11.9527 0.361828 12.1418 0.162079C12.3463 -0.0540261 12.6904 -0.0540267 12.8949 0.162079L17 4.5L12.8949 8.83792C12.6904 9.05403 12.3463 9.05403 12.1418 8.83792C11.9527 8.63817 11.9527 8.32563 12.1418 8.12588L15.573 4.5Z' fill='%237871c8'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0 4.5C0 4.22386 0.223858 4 0.5 4H15.5C15.7761 4 16 4.22386 16 4.5C16 4.77614 15.7761 5 15.5 5H0.5C0.223858 5 0 4.77614 0 4.5Z' fill='%237871c8'/%3E%3C/svg%3E%0A");
      }
    }

    position: relative;

    &::after {
      content: "";
      position: absolute;
      z-index: 10;
      top: 50%;
      left: calc(100% + 5px);

      transform: translate(0%, -25%);
      width: 18px;
      height: 10px;
      transition: $transitionDefault;

      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
      background-image: url("data:image/svg+xml,%3Csvg width='17' height='9' viewBox='0 0 17 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12.6391 8.59606C12.5735 8.66535 12.4632 8.66535 12.3976 8.59606C12.337 8.53201 12.337 8.43179 12.3976 8.36774L16.0577 4.5L12.3976 0.632258C12.337 0.568209 12.337 0.467992 12.3976 0.403942C12.4632 0.334648 12.5735 0.334648 12.6391 0.403942L16.5153 4.5L12.6391 8.59606Z' fill='black'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M15.573 4.5L12.1418 0.874121C11.9527 0.674373 11.9527 0.361828 12.1418 0.162079C12.3463 -0.0540261 12.6904 -0.0540267 12.8949 0.162079L17 4.5L12.8949 8.83792C12.6904 9.05403 12.3463 9.05403 12.1418 8.83792C11.9527 8.63817 11.9527 8.32563 12.1418 8.12588L15.573 4.5Z' fill='black'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0 4.5C0 4.22386 0.223858 4 0.5 4H15.5C15.7761 4 16 4.22386 16 4.5C16 4.77614 15.7761 5 15.5 5H0.5C0.223858 5 0 4.77614 0 4.5Z' fill='black'/%3E%3C/svg%3E%0A");
    }
  }
}
</style>
